import axios from 'axios'
import Vue from 'vue'
import Toasted from 'vue-toasted'
import App from './App.vue'
import './globals.scss'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

axios.defaults.withCredentials = true
axios.defaults.baseURL = window.VUE_APP_BACKEND_URL

Vue.config.productionTip = false
Vue.use(require('vue-shortkey'))
Vue.use(Toasted, { position: 'bottom-center', duration: 3000 })

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')

export { router }

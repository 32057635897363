function User(username, password) {
  var user = new FormData()
  user.append('username', username)
  user.append('password', password)
  return user
}

function TasksFilters({
  task_status = null,
  transaction_ids = null,
  schema_version = null,
  assignement = null,
  page = 1,
  size = 50,
  order_by_created_at = null,
  sorting_order = null,
  transaction_description = null,
  dataset = null,
}) {
  var params = new URLSearchParams()

  if (task_status.toLowerCase() !== 'all') {
    params.append('task_status', task_status.toUpperCase())
  }

  if (schema_version.toLowerCase() !== 'all') {
    params.append('schema_version', schema_version)
  }

  if (transaction_ids !== null) {
    transaction_ids.forEach((transaction_id) => {
      params.append('transaction_ids', transaction_id)
    })
  }

  if (sorting_order !== null) {
    params.append('sorting_order', sorting_order)
  }

  if (order_by_created_at !== null) {
    params.append('order_by_created_at', order_by_created_at)
  }

  if (dataset !== null) {
    params.append('dataset', dataset)
  }

  params.append('page', page)
  params.append('size', size)
  if (assignement !== null) {
    params.append('assignement', assignement)
  }

  if (transaction_description !== null) {
    params.append('transaction_description', transaction_description)
  }
  return params
}

function LabelsFilters({
  transaction_ids = null,
  schema_versions = null,
  current_state = true,
  labels_status = null,
  order_by_created_at = false,
  sorting_order = null,
  page = 1,
  size = 100,
}) {
  var params = new URLSearchParams()
  if (transaction_ids !== null) {
    transaction_ids.forEach((transaction_id) => {
      params.append('transaction_ids', transaction_id)
    })
  }
  if (schema_versions !== null) {
    schema_versions.forEach((schema) => {
      params.append('schema_versions', schema)
    })
  }
  params.append('current_state', current_state)
  if (labels_status !== null) {
    params.append('labels_status', labels_status)
  }

  params.append('order_by_created_at', order_by_created_at)
  if (sorting_order !== null) {
    params.append('sorting_order', sorting_order)
  }
  params.append('page', page)
  params.append('size', size)
  return params
}

function TransactionsFilters({
  dataset = null,
  description = null,
  schema_versions = null,
  with_labels = false,
  with_assigned_pending_tasks = false,
  order_by = null,
  sorting_order = null,
  page = null,
  size = null,
}) {
  let params = new URLSearchParams()
  if (dataset !== null) {
    params.append('dataset', dataset)
  }
  if (description !== null) {
    params.append('description', description)
  }
  if (schema_versions !== null) {
    schema_versions.forEach((schema) => {
      params.append('schema_versions', schema)
    })
  }
  if (with_labels) {
    params.append('with_labels', with_labels)
  }
  if (with_assigned_pending_tasks) {
    params.append('with_assigned_pending_tasks', with_assigned_pending_tasks)
  }
  if (order_by !== null) {
    params.append('order_by', order_by)
  }
  if (sorting_order !== null) {
    params.append('sorting_order', sorting_order)
  }
  if (page !== null) {
    params.append('page', page)
  }
  if (size !== null) {
    params.append('size', size)
  }

  return params
}

export { User, TasksFilters, LabelsFilters, TransactionsFilters }

import axios from 'axios';

const state = {
    user: {
        name: null,
        is_admin: null,
        id: null
    },
};

const getters = {
    isAuthenticated: state => !!state.user.id,
    isAdmin: state => state.user.is_admin,
    getUserName: state => state.user.name,
    getUserId: state => state.user.id
};

const actions = {
    async login({ commit }, token_query) {
        const params = new URLSearchParams(token_query);
        try {
            var res = await axios.get("/api/auth/token", { params: params })
            commit('setUser', res.data)
        } catch (error) {
            this._vm.$toasted.show(error)
        }
    },
    async logout({ commit }) {
        try {
            await axios.get("/api/auth/logout")
            commit('setUser', {
                name: null,
                is_admin: null,
                id: null
            })
            this._vm.$toasted.show("Successfully logged out", { type: "success" })
        } catch (error) {
            this._vm.$toasted.show(error)
        }

    }

};

const mutations = {
    setUser(state, username) {
        state.user = username
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};

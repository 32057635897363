import axios from 'axios'
import { TransactionsFilters } from '../../model/requests_schema'
const state = {
  transactions: [],
  transaction_description: '',
  datasets: [],
  selectedDataset: null,
  withLabels: null,
  withAssignedPendingTasks: null,
  sortingOrder: null,
  orderBy: null,
  schemas: [],
  selectedSchemas: [],
  page: 1,
  total_length_pages: 1,
  transactions_per_page: 100,
}

const getters = {
  getTransactions: (state) => state.transactions,
  getDatasets: (state) => state.datasets,
  getSelectedDataset: (state) => state.selectedDataset,
  getWithLabels: (state) => state.withLabels,
  getWithAssignedPendingTasks: (state) => state.withAssignedPendingTasks,
  getPage: (state) => state.page,
  getNbPages: (state) => {
    return Math.ceil(state.total_length_pages / state.transactions_per_page)
  },
  getTransactionsPerPage: (state) => state.transactions_per_page,
}

const actions = {
  async retrieveLabelSchemas({ commit }) {
    try {
      const res = await axios.get('/api/labels/schemas/')
      const schemas = Object.keys(res.data)
      commit('setLabelSchemas', schemas)
    } catch (error) {
      console.log(error)
      this._vm.$toasted.error(error)
      commit('setLabelSchemas', [])
    }
  },
  async retrieveDatasets({ commit }) {
    try {
      const res = await axios.get('/api/datasets/')
      commit('setDatasets', res.data)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
  async retrieveTransactions({ commit, state }) {
    try {
      const filters = TransactionsFilters({
        dataset: state.selectedDataset,
        description:
          state.transaction_description === ''
            ? null
            : state.transaction_description,
        schema_versions: state.selectedSchemas,
        with_labels: state.withLabels,
        with_assigned_pending_tasks: state.withAssignedPendingTasks,
        order_by: state.orderBy,
        sorting_order: state.sortingOrder,
        page: state.page,
        size: state.transactions_per_page,
      })
      const res = await axios.get('/api/transactions/search/', {
        params: filters,
      })
      commit('setTransactions', res.data)
      commit('setTotalLengthOfPages', res.data.total)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
}

const mutations = {
  setSelectedSchemas(state, selectedSchemas) {
    state.selectedSchemas = selectedSchemas
  },
  setLabelSchemas(state, schemas) {
    state.labelSchemas = schemas
  },
  setOrderBy(state, orderBy) {
    state.orderBy = orderBy
  },
  setSortingOrder(state, sortingOrder) {
    state.sortingOrder = sortingOrder
  },
  setWithAssignedPendingTasks(state, withAssignedPendingTasks) {
    state.withAssignedPendingTasks = withAssignedPendingTasks
  },
  setWithLabels(state, withLabels) {
    state.withLabels = withLabels
  },
  setSelectedDataset(state, selectedDataset) {
    state.selectedDataset = selectedDataset
  },
  setDatasets(state, datasets) {
    state.datasets = datasets
  },
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  setTransactionDescription(state, transaction_description) {
    state.transaction_description = transaction_description
  },
  setTransactionsPerPage(state, transactions_per_page) {
    state.transactions_per_page = transactions_per_page
  },
  setPage(state, page) {
    state.page = page
  },
  setTotalLengthOfPages(state, total_length_pages) {
    state.total_length_pages = total_length_pages
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}

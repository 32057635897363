import axios from 'axios'
import { LabelsFilters } from '../../model/requests_schema.js'

const state = {
  datasets: [],
  selected_dataset: null,
  selected_dataset_to_add: null,
  transaction_ids: null,
  page: 1,
  transactions_per_page: 10,
  total_length: 0,
  transactions: [],
  filter_labeled: true,
  filter_dataset: false,
  filter_schemas: [],
  new_dataset_name: '',
}

const getters = {
  getDatasets: (state) => {
    return state.datasets
  },
  getPage: (state) => {
    return state.page
  },
  getTransactionsPerPage: (state) => {
    return state.transactions_per_page
  },
  getTotalLength: (state) => {
    return state.total_length
  },
  getNbPages: (state) => {
    return Math.ceil(state.total_length / state.transactions_per_page)
  },
  getSelectedDataset: (state) => {
    return state.selected_dataset
  },
  getTransactionIds: (state) => {
    return state.transaction_ids
  },
  getTransactions: (state) => {
    return state.transactions
  },
  getFilterLabeled: (state) => {
    return state.filter_labeled
  },
  getFilterDataset: (state) => {
    return state.filter_dataset
  },
  getFilterSchemas: (state) => {
    return state.filter_schemas
  },
}

// if state none, request labels
const actions = {
  async retrieveDatasets({ commit }) {
    try {
      let res = await axios.get('/api/datasets/')
      commit('setDatasets', res.data)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
  async retrieveDatasetTransactionIds({ commit, state }) {
    if (state.selected_dataset != null) {
      try {
        let res = await axios.get(
          '/api/datasets/' + state.selected_dataset.name + '/transactions/'
        )
        let txs = res.data.map((x) => x.transaction_id)
        commit('setTransactionIds', txs)
      } catch (error) {
        commit('setTransactionIds', null)
        this._vm.$toasted.show(error)
      }
    }
  },
  async retrieveDatasetTransactions({ commit, state }) {
    try {
      commit('setTotalLength', state.transaction_ids.length)
      var txs = state.transaction_ids.slice(
        (state.page - 1) * state.transactions_per_page,
        state.page * state.transactions_per_page
      )
      var params = new URLSearchParams()
      txs.forEach((tx) => {
        params.append('transaction_ids', tx)
      })
      params.append('current_state', true)
      var res = await axios.get('/api/transactions/', { params: params })
      txs = res.data
      let resLabels = await axios.get('/api/labels/', { params: params })
      resLabels = resLabels.data.items
      // loop on the resLabels, for each element, find the index of tx in txs. and add it to labels => schema : label and status
      resLabels.forEach((label) => {
        let tx_index = txs.findIndex((tx) => tx.id == label.transaction_id)
        // if (!("labels" in txs[tx_index])) {
        //     txs[tx_index]["labels"] = {}
        // }
        txs[tx_index][label.schema_version] = label.labels
      })
      txs = txs.map((tx) => {
        tx.amount = tx.amount + ' ' + tx.iso_currency_code
        return tx
      })
      commit('setTransactions', txs)
    } catch (error) {
      commit('setTransactions', null)
      this._vm.$toasted.show(error)
    }
  },
  async retrieveCurrentDataset({ dispatch, state }) {
    if (state.selected_dataset != null) {
      dispatch('retrieveDatasetTransactionIds')
    }
    if (state.filter_labeled) {
      dispatch('retrieveLabeledDataset')
    } else {
      if (state.selected_dataset != null) {
        dispatch('retrieveDatasetTransactions')
      } else {
        this._vm.$toasted.show(
          'Cannot retrieve unlabeled transactions if no dataset selected'
        )
      }
    }
  },
  // retrieve labels joined with transactions (optional transactions ids as input)
  async retrieveLabeledDataset({ commit, state }) {
    try {
      // get category schema version
      let filters = LabelsFilters({
        transaction_ids: state.transaction_ids,
        schema_versions: state.filter_schemas,
        page: state.page,
        size: state.transactions_per_page,
        labels_status: 'ACCEPTED',
      })
      let res = await axios.get('/api/labels/', { params: filters })
      commit('setTotalLength', res.data.total)
      let labels = res.data.items
      var mergedLabels = []
      if (labels.length > 0) {
        var params = new URLSearchParams()
        labels.forEach((labels) => {
          params.append('transaction_ids', labels.transaction_id)
        })
        res = await axios.get('/api/transactions/', { params: params })
        let transactions = res.data
        // transactions = transactions.map(t => Object.fromEntries(
        //     Object.entries(t).map(([k, v]) => [`${"tx_"}${k}`, v])
        // ))
        mergedLabels = labels.map((label) => ({
          ...label,
          ...transactions.find((tx) => tx.id === label.transaction_id),
        }))
      }
      mergedLabels = mergedLabels.map((label) => {
        label.tx_amount = label.tx_amount + ' ' + label.tx_iso_currency_code
        if (label.schema_version.includes('full')) {
          label['ner'] = label.labels.normalizer.ner
          label['normalizer'] = label.labels.normalizer
          label['category'] = label.labels.category
        }
        const label_types = ['ner', 'normalizer', 'category']
        label_types.forEach((label_type) => {
          if (label.schema_version.startsWith(label_type)) {
            label[label_type] = label.labels
          }
        })

        return label
      })
      mergedLabels = mergedLabels.map((tx) => {
        tx.amount = tx.amount + ' ' + tx.iso_currency_code
        return tx
      })
      commit('setTransactions', mergedLabels)
    } catch (error) {
      commit('setTransactions', null)
      this._vm.$toasted.show(error)
    }
  },
  async addTransactionsToDataset({ commit, state }, transactions) {
    try {
      let res = await axios.post(
        '/api/datasets/' +
          state.selected_dataset_to_add.name +
          '/transactions/',
        transactions.map((tx) => tx.id)
      )
      this._vm.$toasted.success('Transactions added to dataset')
      commit('setTransactionIds', res.data)
    } catch (error) {
      this._vm.$toasted.error(error)
    }
  },
  async addTransactionsWithDataset({ commit }, { datasetName, transactions }) {
    try {
      let res = await axios.post(
        '/api/datasets/' + datasetName + '/transactions/',
        transactions.map((tx) => tx.id)
      )
      this._vm.$toasted.success('Transactions added to dataset')
      commit('setTransactionIds', res.data)
    } catch (error) {
      this._vm.$toasted.error(error)
    }
  },
  async removeTransactionsFromDataset(
    { commit },
    { datasetName, transactions }
  ) {
    try {
      let res = await axios.delete(
        '/api/datasets/' + datasetName + '/transactions/',
        {
          data: transactions.map((tx) => tx.id),
        }
      )
      this._vm.$toasted.success(
        'Transactions have been removed from selected dataset'
      )
      commit('setTransactionIds', res.data)
    } catch (error) {
      this._vm.$toasted.error(error)
      this._vm.$toasted.error(
        'Possibly failed because that transaction is not in the dataset'
      )
    }
  },
  async removeDataset({ dispatch }, datasetName) {
    try {
      await axios.delete(`/api/datasets/${datasetName}`)
      this._vm.$toasted.success('Dataset removed successfully')
      dispatch('retrieveDatasets')
    } catch (error) {
      this._vm.$toasted.error(error)
    }
  },
}

const mutations = {
  setDatasets(state, datasets) {
    state.datasets = datasets
  },
  setPage(state, page) {
    state.page = page
  },
  setTotalLength(state, total_length) {
    state.total_length = total_length
  },
  setTransactionsPerPage(state, transactions_per_page) {
    state.transactions_per_page = transactions_per_page
  },
  setSelectedDataset(state, selected_dataset) {
    state.selected_dataset = selected_dataset
  },
  setSelectedDatasetToAdd(state, selected_dataset_to_add) {
    state.selected_dataset_to_add = selected_dataset_to_add
  },
  setTransactionIds(state, transaction_ids) {
    state.transaction_ids = transaction_ids
  },
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  setFilterLabeled(state, filter_labeled) {
    state.filter_labeled = filter_labeled
  },
  setFilterDataset(state, filter_dataset) {
    state.filter_dataset = filter_dataset
  },
  setFilterSchemas(state, filter_schemas) {
    state.filter_schemas = filter_schemas
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}

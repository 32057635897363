import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import datasets from './modules/datasets'
import labelers from './modules/labelers'
import labels from './modules/labels'
import task from './modules/task'
import tasks from './modules/tasks'
import transactions from './modules/transactions'

// Load Vuex
Vue.use(Vuex)
// Create store
export default new Vuex.Store({
  modules: {
    auth,
    task,
    tasks,
    labelers,
    labels,
    datasets,
    transactions,
  },
  plugins: [createPersistedState()],
})

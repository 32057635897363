<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <template v-if="isAdmin">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
      <template v-else>
        <v-app-bar-nav-icon
          v-if="isAuthenticated && isAdmin"
          @click="goDashboard"
        ></v-app-bar-nav-icon>
      </template>

      <v-btn
        icon
        v-if="isAuthenticated"
        @click="goDashboard"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title
        v-if="isAdmin"
        class="d-inline-flex align-center"
        style="position: relative; padding: 15px;"
      >
        {{ getUserName || 'User' }} <span class="custom-chip ml-3">Admin</span>
      </v-toolbar-title>
      <v-tooltip
        bottom
        v-if="isAuthenticated"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="logout"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>
    <template v-if="isAdmin">
      <v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
      >
        <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item
              link
              @click="goDashboard"
            >
              <v-list-item-icon>
                <v-icon>mdi-file-tree</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Tasks</v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              @click="goTransactions"
            >
              <v-list-item-icon>
                <v-icon>mdi-newspaper-variant-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Transactions</v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              @click="goDatasetsList"
            >
              <v-list-item-icon>
                <v-icon>mdi-database</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Datasets</v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              @click="goLabelersList"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Labelers</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </template>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    drawer: false,
    group: null,
  }),
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isAuthenticated', 'getUserName']),
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout', null, { root: true })
      this.$router.push('/login')
      localStorage.removeItem('vuex')
    },
    goDashboard() {
      this.$router.push('/').catch(() => {})
    },
    goTransactions() {
      this.$router.push('/transactions').catch(() => {})
    },
    goDatasetsList() {
      this.$router.push('/datasets')
    },
    goLabelersList() {
      this.$router.push('/labelers')
    },
  },
}
</script>

<style>
html {
  overflow-y: auto;
}

.custom-chip {
  background-color: #ffc107;
  border-radius: 0.25rem;
  color: #000;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 2px 5px;
}
</style>
import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: () => {
        if (!store.getters['auth/isAuthenticated']) {
          return { path: '/login' }
        } else if (!store.getters['auth/isAdmin']) {
          return { path: '/labeler' }
        } else {
          return { path: '/admin' }
        }
      },
    },
    {
      path: '/labeler',
      name: 'Tasks',
      component: () => import('./pages/LabelerHome.vue'),
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/datasets/',
      name: 'Datasets',
      component: () => import('./pages/DatasetsList.vue'),
      meta: {
        adminRequired: true,
      },
    },
    {
      path: '/labelers',
      name: 'Labelers',
      component: () => import('./pages/LabelersList.vue'),
      meta: {
        adminRequired: true,
      },
    },
    {
      path: '/admin',
      name: 'Tasks (Admin View)',
      component: () => import('./pages/AdminHome.vue'),
      meta: {
        adminRequired: true,
      },
    },
    {
      path: '/login',
      name: 'Sign in',
      component: () => import('./pages/Login.vue'),
    },
    {
      path: '/token',
      name: 'Token',
      component: () => import('./pages/Token.vue'),
    },
    {
      path: '/task/:id',
      name: 'Task',
      component: () => import('./pages/Task.vue'),
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/transactions',
      name: 'Transactions',
      component: () => import('./pages/Transactions.vue'),
      meta: {
        authRequired: true,
      },
    },
    { path: '*', component: () => import('./pages/NotFound.vue') },
  ],
})

router.beforeEach((to, _, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  const isAdmin = store.getters['auth/isAdmin']
  const requiresAuth = to.matched.some((record) => record.meta.authRequired)
  const requiresAdmin = to.matched.some((record) => record.meta.adminRequired)

  if (requiresAdmin) {
    if (!isAuthenticated) {
      next({
        path: '/login',
      })
    } else if (!isAdmin) {
      next({
        path: '/labeler',
      })
    } else {
      next()
    }
  } else if (requiresAuth) {
    if (!isAuthenticated) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else if (isAuthenticated && to.path === '/login') {
    router.push('admin')
  } else {
    next()
  }
})

export default router

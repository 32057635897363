import axios from 'axios';

const state = {
    business_labels: null,
    consumer_labels: null,
    label_schemas: [],
}

const getters = {
    getBusinessLabels: state => {
        return state.business_labels
    },
    getConsumerLabels: state => {
        return state.consumer_labels
    },
    getLabelSchemas: state => {
        return state.label_schemas
    }
}

// if state none, request labels
const actions = {
    async retrieveCategorizationLabels({ commit }) {
        try {
            // get category schema version 
            let res = await axios.get('/api/labels/schemas/canonical/');
            const labeler_schema = res.data.categorization
            res = await axios.get('/api/labels/schemas/' + labeler_schema);
            const business_labels = res.data.definitions.BUSINESS_LABELS.enum
            commit("setBusinessLabels", business_labels)
            const consumer_labels = res.data.definitions.CONSUMER_LABELS.enum
            commit("setConsumerLabels", consumer_labels)
        } catch (error) {
            commit("setBusinessLabels", null)
        }
    },
    async retrieveLabelSchemas({ commit }) {
        try {
            // get category schema version 
            var res = await axios.get('/api/labels/schemas/');
            let schemas = Object.keys(res.data)
            commit("setLabelSchemas", schemas)
        } catch (error) {
            commit("setLabelSchemas", [])
        }
    }
}

const mutations = {
    setBusinessLabels(state, business_labels) {
        state.business_labels = business_labels
    },
    setConsumerLabels(state, consumer_labels) {
        state.consumer_labels = consumer_labels
    },
    setLabelSchemas(state, label_schemas) {
        state.label_schemas = label_schemas
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
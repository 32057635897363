import axios from 'axios'
import moment from 'moment'
import { router } from '../../main.js'
import { LabelsFilters, TasksFilters } from '../../model/requests_schema.js'

const state = {
  task_id: null,
  label_id: null,
  transaction_id: null,
  account_holder_type: null,
  amount: null,
  currency: null,
  entry_type: null,
  index: -1,
  text: '',
  labels: null,
  task_status: '',
  created_at: '',
  label_status: '',
  schema: '',
  assignee_id: null,
  loading_status: false,
}
const getters = {
  getTaskId: (state) => {
    return state.task_id
  },
  getLabelId: (state) => {
    return state.label_id
  },
  getTransactionId: (state) => {
    return state.transaction_id
  },
  getAccountHolderType: (state) => {
    return state.account_holder_type
  },
  getAmount: (state) => {
    return state.amount
  },
  getEntryType: (state) => {
    return state.entry_type
  },
  getCurrency: (state) => {
    return state.currency
  },
  getIndex: (state) => {
    return state.index
  },
  getText: (state) => {
    return state.text
  },
  getLabels: (state) => {
    return state.labels
  },
  getTaskStatus: (state) => {
    return state.task_status
  },
  getTaskCreatedAt: (state) => {
    return state.created_at
  },
  getLabelStatus: (state) => {
    return state.label_status
  },
  getSchema: (state) => {
    return state.schema
  },
  getAssigneeId: (state) => {
    return state.assignee_id
  },
  getLoadingStatus: (state) => {
    return state.loading_status
  },
}
const actions = {
  async retrieveTaskForLabeler({ commit, rootState }, task_id) {
    commit('setLoadingStatus', true)
    commit('setTaskId', parseInt(task_id))
    var list_tasks = rootState.tasks.tasks
    var index = -1
    for (let i = 0; i < list_tasks.length; i++) {
      const task = list_tasks[i]
      if (task.id === parseInt(task_id)) {
        index = i
        break
      }
    }
    commit('setIndex', index)
    try {
      var res = await axios.get('/api/tasks/' + task_id)
      commit('setSchema', res.data.schema_version)
      commit('setTaskStatus', res.data.status)
      commit('setTaskCreatedAt', res.data.created_at)
      commit('setAssigneeId', res.data.assignee_id)
      var transaction_id = res.data.transaction_id
      commit('setTransactionId', transaction_id)
      res = await axios.get('/api/transactions/' + transaction_id)
      commit('setText', res.data.description)
      commit('setAmount', res.data.amount)
      commit('setAccountHolderType', res.data.account_holder_type)
      commit('setEntryType', res.data.entry_type)
      commit('setCurrency', res.data.iso_currency_code)
      res = await axios.get('/api/tasks/' + task_id + '/labels/')
      if (res.data != null) {
        commit('setLabelId', res.data.id)
        commit('setLabels', res.data.labels)
      } else {
        commit('setLabelId', null)
        commit('setLabels', null)
      }
    } catch (error) {
      this._vm.$toasted.show(error)
    }
    commit('setLoadingStatus', false)
  },
  async insertLabel({ state, rootState, dispatch, commit }, labels) {
    let label_to_add = {
      transaction_id: state.transaction_id,
      schema_version: state.schema,
      task_id: state.task_id,
      labels: labels,
      info: {},
      status: 'STAGED',
    }
    console.log('sending the following labels', [label_to_add])
    try {
      await axios.post('/api/labels/', [label_to_add])
      dispatch('retrieveTaskForLabeler', state.task_id)
      // if list items == 1 ==> max(1, page --) and index = max
      if (rootState.length === 1) {
        commit('tasks/setPage', Math.max(1, --rootState.tasks.page))
      }
      this._vm.$toasted.show('Label sent successfully')
      return true
    } catch (error) {
      if (error.response) {
        this._vm.$toasted.show(error.response.data.detail) // => the response payload
        if (
          Array.isArray(error.response.data.detail) &&
          error.response.data.detail.length > 0
        ) {
          error.response.data.detail.forEach((errorMsg) => {
            this._vm.$toasted.error(errorMsg.msg) // => the response payload
          })
        }
      } else {
        this._vm.$toasted.error(error)
      }
      return false
    }
  },

  // eslint-disable-next-line no-unused-vars
  async removeLabel(_, label_id) {
    try {
      await axios.delete('/api/labels/' + label_id)
      this._vm.$toasted.show('Label removed successfully')
      return true
    } catch (error) {
      if (error.response) {
        this._vm.$toasted.show(error.response.data.detail) // => the response payload
      } else {
        this._vm.$toasted.show(error)
      }
      return false
    }
  },

  async updateLabel({ state, rootState, dispatch, commit }, labels) {
    try {
      await axios.patch('/api/labels/' + state.label_id, { labels: labels })
      await dispatch('retrieveTaskForLabeler', state.task_id)
      if (rootState.tasks.assignement == 'myself' || !rootState.auth.is_admin) {
        commit('tasks/setCurrentLabelerId', rootState.auth.user.id, {
          root: true,
        })
        await dispatch('tasks/retrieveTasksForLabeler', null, { root: true })
      } else {
        await dispatch('tasks/retrieveAllTasks', null, { root: true })
      }
      this._vm.$toasted.show('Label updated successfully')
    } catch (error) {
      if (error.response) {
        this._vm.$toasted.show(error.response.data.detail) // => the response payload
      } else {
        this._vm.$toasted.show(error)
      }
    }
  },
  async acceptLabel({ state, rootState, dispatch, commit }) {
    try {
      // retrieve mutated state first before sending request
      await dispatch('retrieveTaskForLabeler', state.task_id)
      await axios.put(`/api/labels/${state.label_id}`)
      if (rootState.tasks.assignement == 'myself') {
        commit('setCurrentLabelerId', rootState.auth.user.id)
        await dispatch('tasks/retrieveTasksForLabeler', null, { root: true })
      } else {
        await dispatch('tasks/retrieveAllTasks', null, { root: true })
      }
      this._vm.$toasted.show('Label accepted successfully', { type: 'success' })
    } catch (error) {
      if (error.response) {
        this._vm.$toasted.show(error.response.data.detail[0].msg, {
          type: 'error',
        }) // => the response payload
      } else {
        this._vm.$toasted.show(error, { type: 'error' })
      }
    }
  },
  async rejectLabel({ state, rootState, dispatch, commit }) {
    try {
      await axios.delete('/api/labels/' + state.label_id)
      await dispatch('retrieveTaskForLabeler', state.task_id)
      if (rootState.tasks.assignement == 'myself') {
        commit('setCurrentLabelerId', rootState.auth.user.id)
        await dispatch('tasks/retrieveTasksForLabeler', null, { root: true })
      } else {
        await dispatch('tasks/retrieveAllTasks', null, { root: true })
      }
      this._vm.$toasted.show('Label Rejected successfully')
    } catch (error) {
      if (error.response) {
        this._vm.$toasted.show(error.response.data.detail) // => the response payload
      } else {
        this._vm.$toasted.show(error)
      }
    }
  },
  async next({ state, rootState, commit, getters, rootGetters, dispatch }) {
    var index = state.index
    if (index >= rootState.tasks.tasks.length) {
      index = rootState.tasks.tasks.length - 1
      commit('setIndex', index)
    }
    if (index < 0) {
      index = 0
      commit('setIndex', index)
    }
    // if index dont match current task, take this one and return
    var target_task_id = rootState.tasks.tasks[state.index].id
    if (target_task_id === state.task_id) {
      // if the current task is matching, we increment the index
      if (index < rootState.tasks.tasks.length - 1) {
        commit('setIndex', index + 1)
      } else {
        // next page if there is one
        if (rootGetters['tasks/getPage'] < rootGetters['tasks/getNbPages']) {
          commit('tasks/setPage', rootGetters['tasks/getPage'] + 1, {
            root: true,
          })
          commit('setIndex', 0)
        } else {
          commit('tasks/setPage', 1, { root: true })
          commit('setIndex', 0)
        }
        if (rootGetters['auth/isAdmin']) {
          if (rootGetters['tasks/getAssignementFilter'] == 'myself') {
            commit('tasks/setCurrentLabelerId', rootGetters['auth/getUserId'], {
              root: true,
            })
            dispatch('tasks/retrieveTasksForLabeler', null, { root: true })
          } else {
            await dispatch('tasks/retrieveAllTasks', null, { root: true })
          }
        } else {
          await dispatch('tasks/retrieveTasksForLabeler', null, { root: true })
        }
      }
    }
    var new_task_id = rootState.tasks.tasks[getters.getIndex].id

    await dispatch('retrieveTaskForLabeler', new_task_id)

    router.push(`/task/${new_task_id}`).catch((e) => {
      this._vm.$toasted.show('No more tasks to load', { type: 'info' })
      console.log(e)
    })
  },
  async assignTask(_, { task_id, assignee_id }) {
    try {
      await axios.put('/api/tasks/' + task_id + '/labelers/' + assignee_id)
      this._vm.$toasted.show('Task assigned!')
    } catch (error) {
      if (error.response) {
        this._vm.$toasted.show(error.response.data.detail) // => the response payload
      } else {
        this._vm.$toasted.show(error)
      }
    }
  },
  async autoAssignNewTask({ getters, rootGetters }) {
    const filters = TasksFilters({
      task_status: 'TODO',
      schema_version: getters.getSchema,
    })
    try {
      var res = await axios.get(
        '/api/tasks/labelers/' + rootGetters['auth/getUserId'],
        { params: filters }
      )
      if (res.data.total < window.VUE_APP_NB_TASKS_AUTOASSIGN) {
        var params = {
          schema_version: getters.getSchema,
          num_tasks: window.VUE_APP_NB_TASKS_AUTOASSIGN,
        }
        res = await axios.put(
          '/api/tasks/labelers/' + rootGetters['auth/getUserId'],
          {},
          { params: params }
        )
        if (res.status == 200) {
          this._vm.$toasted.show('New tasks autoassigned')
        }
      }
    } catch (error) {
      if (error.response) {
        this._vm.$toasted.show(error.response.data.detail) // => the response payload
      } else {
        this._vm.$toasted.show(error)
      }
    }
  },
  async createTask(_, { transaction_id, schema_version, assignee_id }) {
    try {
      await axios.post(
        '/api/tasks/' + schema_version + '/transactions/',
        [transaction_id],
        { params: { assignee_id: assignee_id } }
      )
      this._vm.$toasted.show('Task created successfully', { type: 'success' })
    } catch (error) {
      if (error.response) {
        this._vm.$toasted.show(error.response.data.detail) // => the response payload
      } else {
        this._vm.$toasted.show(error)
      }
    }
  },
  async createTasks(_, { transaction_ids, schema_version, assignee_id }) {
    try {
      await axios.post(
        '/api/tasks/' + schema_version + '/transactions/',
        transaction_ids,
        { params: { assignee_id: assignee_id } }
      )
      this._vm.$toasted.show('Task created successfully')
    } catch (error) {
      if (error.response) {
        this._vm.$toasted.show(error.response.data.detail) // => the response payload
      } else {
        this._vm.$toasted.show(error)
      }
    }
  },
  async prefillTask({ state }) {
    try {
      let filters = LabelsFilters({
        transaction_ids: [state.transaction_id],
        schema_versions: [state.schema],
      })
      let res = await axios.get('/api/labels/', { params: filters })
      if (res.data.items.length > 0) {
        return res.data.items[0].labels
      } else {
        this._vm.$toasted.show('Could not find a label for this transaction')
        return null
      }
    } catch (error) {
      this._vm.$toasted.info(error)
      return null
    }
  },
}
const mutations = {
  setTaskId(state, task_id) {
    state.task_id = task_id
  },
  setLabelId(state, label_id) {
    state.label_id = label_id
  },
  setTransactionId(state, transaction_id) {
    state.transaction_id = transaction_id
  },
  setAccountHolderType(state, account_holder_type) {
    state.account_holder_type = account_holder_type
  },
  setAmount(state, amount) {
    state.amount = amount
  },
  setEntryType(state, entry_type) {
    state.entry_type = entry_type
  },
  setCurrency(state, currency) {
    state.currency = currency
  },
  setIndex(state, index) {
    state.index = index
  },
  setText(state, text) {
    state.text = text
  },
  setLabels(state, labels) {
    state.labels = labels
  },
  setTaskStatus(state, task_status) {
    state.task_status = task_status
  },
  setTaskCreatedAt(state, created_at) {
    let formattedDate = moment(created_at).format('YYYY-MM-DD HH:mm:ss')
    state.created_at = formattedDate
  },
  setLabelStatus(state, label_status) {
    state.label_status = label_status
  },
  setSchema(state, schema) {
    state.schema = schema
  },
  setAssigneeId(state, assignee_id) {
    state.assignee_id = assignee_id
  },
  setLoadingStatus(state, loading_status) {
    state.loading_status = loading_status
  },
  clearTask(state) {
    state.task_id = null
    state.transaction_id = null
    state.annotations = []
    state.account_holder_type = null
    state.currency = null
    state.amount = null
    state.entry_type = null
    state.text = ''
    state.index = -1
    state.labels = null
    state.task_status = ''
    state.label_status = ''
    state.schema = ''
    state.assignee_id = null
    state.loading_status = false
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}

import axios from 'axios'

const state = {
  labelers: [],
  labeler: null,
  page: 1,
  labelers_per_page: 10,
  total_length: 1,
  only_active: true,
  order_by_name: true,
  include_admins: false,
}
const getters = {
  getLabelers: (state) => {
    return state.labelers
  },
  getLabeler: (state) => {
    return state.labeler
  },
  getLabelersPage: (state) => {
    return state.page
  },
  getLabelersPerPage: (state) => {
    return state.labelers_per_page
  },
  getLabelersTotalLength: (state) => {
    return state.total_length
  },
  getLabelersNbPages: (state) => {
    return Math.ceil(state.total_length / state.labelers_per_page)
  },
  getActiveFilter: (state) => {
    return state.only_active
  },
  getOrderByNameFilter: (state) => {
    return state.order_by_name
  },
  getIncludeAdminsFilter: (state) => {
    return state.include_admins
  },
}
const actions = {
  async retrieveLabelers({ commit, state }) {
    const filters = {
      only_active: state.only_active,
      order_by_name: state.order_by_name,
      include_admins: state.include_admins,
      size: state.labelers_per_page,
      page: state.page,
    }
    try {
      var res = await axios.get('/api/labelers/', { params: filters })
      commit('setLabelersTotalLength', res.data.total)
      commit('setLabelers', res.data.items)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
  async retrieveLabeler({ commit }, id) {
    try {
      var res = await axios.get('/api/labelers/' + id)
      commit('setLabeler', res.data)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
  async createLabeler({ dispatch }, { name, email, is_admin }) {
    const params = {
      email_address: email,
      name: name,
      is_admin: is_admin,
    }
    try {
      await axios.post('/api/labelers/', params)
      await dispatch('retrieveLabelers')
      this._vm.$toasted.show('User created successfully!')
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
  async updateLabelerRights({ dispatch }, { labeler_id, make_superuser }) {
    try {
      await axios.put('/api/labelers/' + labeler_id, {
        params: { make_superuser: make_superuser },
      })
      this._vm.$toasted.show('User rights updated successfully!')
      await dispatch('retrieveLabelers')
      await dispatch('retrieveLabeler', labeler_id)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
  async deactivateLabeler({ dispatch }, labeler_id) {
    try {
      await axios.delete('/api/labelers/' + labeler_id)
      this._vm.$toasted.show('User deactivated successfully!')
      await dispatch('retrieveLabelers')
      await dispatch('retrieveLabeler', labeler_id)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
  async reactivateLabeler({ dispatch }, labeler_id) {
    try {
      await axios.patch('/api/labelers/' + labeler_id)
      this._vm.$toasted.show('User reactivated successfully!')
      await dispatch('retrieveLabelers')
      await dispatch('retrieveLabeler', labeler_id)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
}
const mutations = {
  setLabelers(state, labelers) {
    state.labelers = labelers
  },
  setLabeler(state, labeler) {
    state.labeler = labeler
  },
  setLabelersTotalLength(state, total_length) {
    state.total_length = total_length
  },
  setLabelersPerPage(state, labelers_per_page) {
    state.labelers_per_page = labelers_per_page
  },
  setLabelersPage(state, page) {
    state.page = page
  },
  setActiveFilter(state, only_active) {
    state.only_active = only_active
  },
  setOrderByNameFilter(state, order_by_name) {
    state.order_by_name = order_by_name
  },
  setIncludeAdminsFilter(state, include_admins) {
    state.include_admins = include_admins
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}

import axios from 'axios'
import { TasksFilters } from '../../model/requests_schema.js'

const state = {
  current_labeler_id: null,
  tasks: [],
  allTasks: [],
  page: 1,
  task_per_page: 20,
  total_length: 1,
  schema: 'all',
  status: 'all',
  filter_on_dataset: null,
  order_by_created_at: false,
  sorting_order: 'desc',
  assignement: 'any',
  matchingCompanies: [],
  transaction_description: '',
  selected_dataset: null,
}
const getters = {
  getCurrentLabelerId: (state) => {
    return state.current_labeler_id
  },
  getTasks: (state) => {
    return state.tasks
  },
  getPage: (state) => {
    return state.page
  },
  getTasksPerPage: (state) => {
    return state.task_per_page
  },
  getTotalLength: (state) => {
    return state.total_length
  },
  getNbPages: (state) => {
    return Math.ceil(state.total_length / state.task_per_page)
  },
  getSchemaFilter: (state) => {
    return state.schema
  },
  getStatusFilter: (state) => {
    return state.status
  },
  getOrderByCreatedAt: (state) => {
    return state.order_by_created_at
  },
  getAssignementFilter: (state) => {
    return state.assignement
  },
  getFilterOnDataset: (state) => {
    return state.filter_on_dataset
  },
  getAllTasks: (state) => {
    return state.allTasks
  },
  getMatchingCompanies: (state) => {
    return state.matchingCompanies
  },
  getSelectedDataset: (state) => {
    return state.selected_dataset
  },
}
const actions = {
  async retrieveTasksForLabeler({ commit, dispatch, rootState }) {
    if (rootState.datasets.selected_dataset != null) {
      await dispatch('datasets/retrieveDatasetTransactionIds', null, {
        root: true,
      })
    } else {
      rootState.datasets.transaction_ids = []
    }
    const filters = TasksFilters({
      task_status: rootState.tasks.status,
      transaction_ids: rootState.datasets.transaction_ids,
      schema_version: rootState.tasks.schema,
      page: rootState.tasks.page,
      size: rootState.tasks.task_per_page,
      sorting_order: rootState.tasks.sorting_order,
      order_by_created_at: rootState.tasks.order_by_created_at,
      dataset: rootState.tasks.selected_dataset,
    })
    var labeler_id = rootState.tasks.current_labeler_id
    let res
    try {
      if (labeler_id) {
        res = await axios.get('/api/tasks/labelers/' + labeler_id, {
          params: filters,
        })
      } else {
        res = await axios.get('/api/tasks/', {
          params: filters,
        })
      }
      let tasks = res.data.items
      commit('setTotalLength', res.data.total)
      // auto assign tasks if user is not an admin (but is a labeler)
      var mergedTasks = []
      // Merge with transaction details (to display text)
      if (tasks.length > 0) {
        var params = new URLSearchParams()
        tasks.forEach((task) => {
          params.append('transaction_ids', task.transaction_id)
        })
        res = await axios.get('/api/transactions/', { params: params })
        let transactions = res.data
        transactions = transactions.map((t) =>
          Object.fromEntries(
            Object.entries(t).map(([k, v]) => [`${'tx_'}${k}`, v])
          )
        )
        mergedTasks = tasks.map((task) => ({
          ...task,
          ...transactions.find((tx) => tx.tx_id === task.transaction_id),
        }))
      }
      try {
        let labelerEmailFromId
        if (labeler_id) {
          labelerEmailFromId = await axios.get('/api/labelers/' + labeler_id)
        } else {
          labelerEmailFromId = await axios.get(
            '/api/labelers/?only_active=true&order_by_name=true&include_admins=true'
          )
        }

        if (labelerEmailFromId.data.items.length > 0) {
          mergedTasks.forEach((task) => {
            let findLabeler = labelerEmailFromId.data.items.find(
              (labeler) => labeler.id === task.assignee_id
            )
            if (findLabeler) {
              task.labeler_email = findLabeler.email_address
            }
          })
        } else {
          mergedTasks.forEach((task) => {
            task.labeler_email = labelerEmailFromId.data.email_address
          })
        }
      } catch (error) {
        this._vm.$toasted.show(error)
      }

      commit('setTasks', mergedTasks)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
  async retrieveAllTasks({ commit, dispatch, rootState }) {
    // if dataset name selected, retrieve dataset txs
    // else, set dataset txs to null)
    if (rootState.datasets.selected_dataset != null) {
      await dispatch('datasets/retrieveDatasetTransactionIds', null, {
        root: true,
      })
    } else {
      rootState.datasets.transaction_ids = []
    }
    const filters = TasksFilters({
      task_status: rootState.tasks.status,
      transaction_ids: rootState.datasets.transaction_ids,
      schema_version: rootState.tasks.schema,
      page: rootState.tasks.page,
      size: rootState.tasks.task_per_page,
      assignement: rootState.tasks.assignement,
      sorting_order: rootState.tasks.sorting_order,
      order_by_created_at: rootState.tasks.order_by_created_at,
      // add transaction_description only if its not null
      transaction_description: rootState.tasks.transaction_description
        ? rootState.tasks.transaction_description
        : null,
      dataset: rootState.tasks.selected_dataset,
    })

    try {
      let res = null
      let tasks = []
      if (rootState.datasets.transaction_ids.length > 2000) {
        for (
          let i = 0;
          i < rootState.datasets.transaction_ids.length;
          i += 2000
        ) {
          filters.transaction_ids = rootState.datasets.transaction_ids.slice(
            i,
            i + 2000
          )
          res = await axios.get('/api/tasks/', { params: filters })
          tasks = [...tasks, ...res.data.items]
          commit('setTotalLength', res.data.total)
        }
      } else {
        res = await axios.get('/api/tasks/', { params: filters })
        tasks = res.data.items
        commit('setTotalLength', res.data.total)
      }
      var mergedTasks = []
      // Merge with transaction details (to display text)
      if (tasks.length > 0) {
        var params = new URLSearchParams()
        tasks.forEach((task) => {
          params.append('transaction_ids', task.transaction_id)
        })
        res = await axios.get('/api/transactions/', { params: params })
        let transactions = res.data
        transactions = transactions.map((t) =>
          Object.fromEntries(
            Object.entries(t).map(([k, v]) => [`${'tx_'}${k}`, v])
          )
        )
        mergedTasks = tasks.map((task) => ({
          ...task,
          ...transactions.find((tx) => tx.tx_id === task.transaction_id),
        }))
        var resMergedTasks = []
        for (const task of mergedTasks) {
          if (task.assignee_id === null) {
            resMergedTasks.push(task)
          } else {
            let res = await axios.get('/api/labelers/' + task.assignee_id)
            var task_copy = task
            task_copy.assignee_id = res.data.email_address
          }
        }
      }
      commit('setTasks', mergedTasks)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
  async handleGetAllTasks({ commit, rootState }) {
    let allTasks = []
    const filters = TasksFilters({
      task_status: rootState.tasks.status,
      schema_version: rootState.tasks.schema,
      page: rootState.tasks.page,
      size: 2000,
      assignement: rootState.tasks.assignement,
      sorting_order: rootState.tasks.sorting_order,
      order_by_created_at: rootState.tasks.order_by_created_at,
    })
    try {
      var res = await axios.get('/api/tasks/', { params: filters })
      allTasks = res.data.items
    } catch (error) {
      this._vm.$toasted.show(error)
    }

    commit('setAllTasks', allTasks)
  },
  async fetchMatchingCompanies({ commit }, name) {
    try {
      const res = await axios.get('/api/companies/', {
        params: {
          name: name,
        },
      })
      commit('setMatchingCompanies', res.data)
    } catch (error) {
      this._vm.$toasted.show(error)
    }
  },
}
const mutations = {
  setCurrentLabelerId(state, current_labeler_id) {
    state.current_labeler_id = current_labeler_id
  },
  setTasks(state, tasks) {
    state.tasks = tasks
  },
  setTotalLength(state, total_length) {
    state.total_length = total_length
  },
  setTasksPerPage(state, task_per_page) {
    state.task_per_page = task_per_page
  },
  setPage(state, page) {
    state.page = page
  },
  setSchemaFilter(state, schema) {
    state.schema = schema
  },
  setStatusFilter(state, status) {
    state.status = status
  },
  setOrderByCreatedAt(state, order_by_created_at) {
    state.order_by_created_at = order_by_created_at
  },
  setSortingOrder(state, sorting_order) {
    state.sorting_order = sorting_order
  },
  setAssignementFilter(state, assignement) {
    state.assignement = assignement
  },
  setFilterOnDataset(state, filter_on_dataset) {
    state.filter_on_dataset = filter_on_dataset
  },
  setAllTasks(state, allTasks) {
    state.allTasks = allTasks
  },
  setMatchingCompanies(state, matchingCompanies) {
    state.matchingCompanies = matchingCompanies
  },
  setTransactionDescription(state, transaction_description) {
    state.transaction_description = transaction_description
  },
  setSelectedDataset(state, selected_dataset) {
    state.selected_dataset = selected_dataset
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
